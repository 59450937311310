<template>
  <FormComponent class="register" @submit="submit">
    <h3 class="auth-title">
      {{ $t('login-invite.welcome') }} {{ account_name }}
    </h3>
    <div class="block-el">
      <template v-if="fetched">
        <template v-if="type == 'login'">
          <InputComponent name="email" type="email" :label="$tc('app.email', 1)"
                          :placeholder="$t('app.email')"
                          v-model="form.email" disabled></InputComponent>
          <PasswordInputComponent :label="$tc('app.password', 1)"
                                  :placeholder="$t('login-invite.fullname')" required
                                  v-model="form.password"></PasswordInputComponent>
          <div class="text-center">
            <router-link to="/reset-password">{{
                $t('login-invite.forgot-password')
              }}
            </router-link>
          </div>
          <ButtonComponent type="submit" is-block :loading="isSending">
            {{ $t('app.login') }}
          </ButtonComponent>
        </template>
        <template v-else>
          <InputComponent :label="$tc('app.name', 1)" name="name" :placeholder="$tc('app.name', 1)"
                          required
                          v-model="form.name"></InputComponent>
          <TelInputComponent :label="$tc('app.phone', 1)" v-model="form.mobile_number"
                             required></TelInputComponent>
          <InputComponent name="email" type="email" :label="$tc('app.email', 1)"
                          :placeholder="$t('app.email')"
                          v-model="form.email" disabled></InputComponent>
          <PasswordInputComponent :label="$tc('app.password', 1)"
                                  :placeholder="$t('login-invite.fullname')" required
                                  v-model="form.password"></PasswordInputComponent>
          <CheckboxComponent v-model="form.terms" required>Li e concordo com os
            <a id="show-btn" href="javascript:void(0)" @click="showModal()">termos de uso</a>
            <b-modal ref="my-modal" size="xl" scrollable hide-footer title="Termos">
              <div class="container-fluid">
                <ModalContentTerms/>
              </div>
            </b-modal>
          </CheckboxComponent>
          <ButtonComponent type="submit" is-block :loading="isSending">
            {{ $t('app.register') }}
          </ButtonComponent>
        </template>
      </template>
      <div v-else class="qt-block-ui"/>
    </div>
  </FormComponent>
</template>

<script>
// @ is an alias to /src
import UserService from '@/services/user.service';
import { load } from 'recaptcha-v3';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import moment from 'moment';
import FormComponent from '@/components/form/FormComponent.vue';
import InputComponent from '@/components/form/InputComponent.vue';
import TelInputComponent from '@/components/form/TelInputComponent.vue';
import PasswordInputComponent from '@/components/form/PasswordInputComponent.vue';
import ModalContentTerms from '@/components/rebranding/ModalContentTerms.vue';
import CheckboxComponent from '@/components/form/CheckboxComponent.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';

export default {
  name: 'Invitation',
  components: {
    ButtonComponent,
    CheckboxComponent,
    ModalContentTerms,
    PasswordInputComponent,
    TelInputComponent,
    InputComponent,
    FormComponent,
  },
  data() {
    return {
      gender: null,
      customGender: null,
      fetched: false,
      isSending: false,
      countries: [],
      mobile_number: null,
      invalidPhone: false,
      valid_password: false,
      bindProps: {
        mode: 'international',
        defaultCountry: 'BR',
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: true,
        placeholder: this.$t('generic-str.lbl-cellphone'),
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        validCharactersOnly: true,
        preferredCountries: ['AU', 'BR'],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone',
        maxLen: 25,
        wrapperClasses: '',
        inputClasses: 'form-control',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
      type: '',
      account_name: '',
      placeHolderImage: null,
      form: {
        name: '',
        email: '',
        password: '',
        mobile_number: '',
        terms: false,
        // profile_picture: '',
      },
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show();
    },
    fetch() {
      UserService.getInvitation(this.$route.params.token)
        .then(
          (response) => {
            this.form.name = response.name;
            this.form.email = response.email;
            this.form.token = this.$route.params.token;
            this.form.account_name = response.account_name;
            this.account_name = response.account_name;

            if (response.name) this.type = 'login';
            else this.type = 'register';
          },
          () => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: this.$t('warnings.user.invalid-invite'),
              type: 'danger',
            });
            this.$router.push('/login');
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    recaptcha(callback) {
      load(process.env.VUE_APP_RECAPTCHA_KEY, {
        useRecaptchaNet: true,
        autoHideBadge: true,
      }).then((recaptcha) => {
        // recaptcha.execute('refresh').then((token) => {
        //   console.log(token);
        //   this.form.recaptcha_token = token;
        //   callback();
        // });
        callback();
      });
    },
    submit() {
      this.isSending = true;
      this.recaptcha(() => {
        this.$store.dispatch(`auth/${this.type}`, this.form).then(
          () => {
            this.$store.dispatch('auth/login', this.form).then(
              (response) => {
                this.isSending = false;
                this.$router.push('/');
              },
              (error) => {
                this.$toast.show({
                  title: this.$t('sms.infos.filters.status.lbl-error'),
                  content: error.message,
                  type: 'danger',
                });
                this.isSending = false;
              },
            );
          },
          (error) => {
            console.log(error);
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        );
      });
    },
  },
};
</script>

<style scoped lang="scss">
.sign-in-heading {
  margin: 0;
}

.profile-pic {
  padding: 1.2rem 0;
  text-align: center;

  picture {
    display: block;
    border-radius: 105px;
    margin: 0 auto;
    height: 105px;
    width: 105px;
    background-color: #f1f1f1;
    border: 0.15rem solid #752de6;
    position: relative;
    overflow: hidden;

    button {
      background-color: transparent;
      border: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      color: #752de6;
      font-size: 1.5rem;
      outline: none;

      .material-icons-outlined {
        display: block;
      }
    }
  }
}

.block-el {
  min-height: 200px;
}

/* Rebranding */
.vti__dropdown-list {
  z-index: 1000 !important;
}

/* .vti__dropdown {
  background-color: #fff;
} */

.vue-tel-input {
  border-color: #dfe7f3 !important;
  border: 1px solid rgba(120, 141, 180, 0.3) !important;
}

.vue-tel-input:focus {
  outline: none;
}

::v-deep {
  .vti__dropdown-list {
    z-index: 3 !important;
  }

  .vti__dropdown {
    background-color: var(--form-input) !important;
  }

  .bootstrap-datetimepicker-widget.dropdown-menu {
    width: fit-content;
  }

  .bootstrap-datetimepicker-widget.dropdown-menu ul {
    display: flex !important;
    // padding-right: 1rem;
  }

  .bootstrap-datetimepicker-widget.dropdown-menu ul i {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 5px;
    height: 26px;
  }

  .bootstrap-datetimepicker-widget.dropdown-menu
  ul
  .picker-switch.accordion-toggle
  a[data-action='clear']
  i {
    display: none;
  }

  .bootstrap-datetimepicker-widget.dropdown-menu
  ul
  .picker-switch.accordion-toggle
  a {
    height: 32px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    width: 25px;
    justify-content: center;
  }

  .bootstrap-datetimepicker-widget.dropdown-menu
  ul
  .picker-switch.accordion-toggle
  a:hover {
    background: #f0f6ff;
  }

  .bootstrap-datetimepicker-widget.dropdown-menu
  ul
  .picker-switch.accordion-toggle
  a[data-action='clear']::before {
    content: '\e872';
    font-family: 'Material Symbols Outlined';
    font-size: 24px;
    font-weight: 400;
    cursor: pointer;
    color: var(--gray-font-color);
  }
}

/* input:-ms-input-placeholder {
  text-transform: lowercase !important;
}
input::-webkit-input-placeholder {
  text-transform: lowercase !important;
}
input::placeholder {
  text-transform: lowercase !important;
} */
</style>
